import { getAgencyApiHost, getApiHost } from 'api/host-by-env';
import { handleError } from 'utilities/utilities';
import {
  DocumentRequest,
  DocumentResponse,
  FileRestrictionRequest,
  ParseAddressRequest,
  ParseAddressResponse,
  SearchRequest,
  SearchResponse,
  StatesCountiesResponse,
} from './interfaces';

export async function getStatesCountiesData(token: string, firmId: string): Promise<StatesCountiesResponse[]> {
  const url = `${getApiHost()}backtitle/states-counties/${firmId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function performSearch(token: string, request: SearchRequest): Promise<SearchResponse> {
  const url = `${getApiHost()}backtitle/search`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch((error) => {
    throw Error(`Error occurred while performing BackTitle search. payload - ${request} , error - : ${error}`);
  });
  const json = await response.json();
  return json.result;
}

export async function getDocument(token: string, request: DocumentRequest): Promise<DocumentResponse> {
  const url = `${getAgencyApiHost()}backtitle-service/v1/document`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch((error) => {
    console.log(error);
    throw Error(
      `Error occurred while performing BackTitle GET Document. payload - ${JSON.stringify(
        request,
      )} , error - : ${JSON.stringify(error)}`,
    );
  });
  const json = await response.json();
  return json.Result;
}

export async function parseAddress(token: string, request: ParseAddressRequest): Promise<ParseAddressResponse> {
  const url = `${getApiHost()}backtitle/parse-address`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch((error) => {
    throw Error(`Error occurred while parsing BackTitle Address. payload - ${request} , error - : ${error}`);
  });
  const json = await response.json();
  return json.result;
}

export async function getFileRestriction(token: string, request: FileRestrictionRequest): Promise<boolean> {
  const url = `${getApiHost()}backtitle/file-restriction`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).catch((error) => {
    throw Error(`Error occurred while getting BackTitle File Restriction. payload - ${request} , error - : ${error}`);
  });
  const json = await response.json();
  handleError(json);
  return !json.result.BackTitleStatus || !json.result.BackTitleCountyAvailable;
}
