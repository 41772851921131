import { gql } from '@apollo/client';
import { Article } from '../types';
import performQuery from './contentfulClient';

const SEARCH_ARTICLES_COLLECTION = gql`
  query SearchArticles($searchText: String!, $skip: Int!, $limit: Int!, $preview: Boolean) {
    ansArticleCollection(
      where: {
        OR: [
          { articleTitle_contains: $searchText }
          { articleSummary_contains: $searchText }
          { articleBody_contains: $searchText }
        ]
      }
      limit: $limit
      skip: $skip
      preview: $preview
    ) {
      total
      items {
        sys {
          id
          spaceId
        }
        articleTitle
        articleSummary
        articleTip
        slug
        subTopicCollection(limit: 1) {
          items {
            topicName
            slug
          }
        }
      }
    }
  }
`;

export const useSearchArticlesQuery = async (searchText?: string, skip = 0, limit = 10) => {
  const response = await performQuery(SEARCH_ARTICLES_COLLECTION, { searchText, skip, limit });

  if (response.hasError) {
    return {
      data: null,
      error: true,
    };
  }

  return {
    data: response.res?.data?.ansArticleCollection?.items as Article[] | null,
    total: response.res?.data?.ansArticleCollection?.total,
    error: false,
  };
};
