import { createStyles, Grid, makeStyles, Theme, Typography } from '@agentnet/components';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { Article } from '../../types';
import { RenderContent } from '../RenderContent/RenderContent';
import { ArticleTip } from './ArticleTip';

type ArticleBodyProps = {
  article: Article | undefined;
  isPdf: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    articleBody: {
      marginTop: theme.spacing(1),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
  }),
);
function ArticleBody({ article, isPdf }: ArticleBodyProps) {
  const classes = useStyles();
  const history = useHistory();

  // const { getAccessToken } = useAuth();
  // const { addGlobalMsg } = useGlobalMessages();
  // const { addSnackbarMessage } = useSnackBars();
  // const [downloadingArticle, setDownloadingArticle] = useState(false);

  // const downloadArticlePdf = async () => {
  //   setDownloadingArticle(true);
  //   addSnackbarMessage({
  //     message: `Generating Help Article...`,
  //     type: 'success',
  //   });

  //   const token = await getAccessToken();
  //   await generateHelpArticle(article?.slug ?? '', token, addGlobalMsg);

  //   setDownloadingArticle(false);
  // };

  return (
    <Grid container>
      {article && (
        <>
          {!isPdf && (
            <AgentNetButton
              className={classes.mb2}
              variant="text"
              size="medium"
              color="primary"
              startIcon={<ArrowBack htmlColor="primary" />}
              onClick={() => history.goBack()}
            >
              Back
            </AgentNetButton>
          )}
          <Grid item sm={12}>
            <Typography variant="h1">{article?.articleTitle}</Typography>
          </Grid>
          {/* {!isPdf && (
            <AgentNetButton
              className={classes.mb2}
              variant="text"
              size="medium"
              color="primary"
              onClick={() => downloadArticlePdf()}
              disabled={downloadingArticle}
            >
              Download
            </AgentNetButton>
          )} */}
          <Grid item sm={12}>
            <div className={classes.articleBody}>
              <RenderContent document={article?.articleBody} />
            </div>
          </Grid>
          <ArticleTip articleTip={article?.articleTip} />
        </>
      )}
    </Grid>
  );
}

export { ArticleBody };
