import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { checkPdfToken } from 'api/pdf/pdf-api';
import { ArticleBody } from 'features/help/components/TopicBody/ArticleBody';
import { useSearchParams } from 'features/help/hooks/useSearchParams';
import { useArticleQuery } from 'features/help/queries/useArticleQuery';
import { Article } from 'features/help/types';
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

export function HelpArticle() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      loaderContainer: {
        height: '40vw',
        width: '65vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      subTopPageBody: {
        width: '100%',
        background: '#fff',
      },
    }),
  );

  const classes = useStyles();
  const params = useSearchParams<{ pdfToken?: string }>();
  const match = useRouteMatch<{ article: string }>();
  const history = useHistory();

  const [article, setArticle] = useState<Article | null>();

  useEffect(() => {
    const fetchData = async () => {
      const res = await checkPdfToken(params.pdfToken ?? '');
      if (!res) {
        history.push('/');
        return;
      }

      useArticleQuery(match.params.article).then((res) => {
        setArticle(res.data);
        document.title = res.data?.articleTitle ?? 'Help Article';
      });
    };

    fetchData();
  }, []);

  return <div className={classes.subTopPageBody}>{article && <ArticleBody article={article} isPdf={true} />}</div>;
}
