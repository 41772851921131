import { createStyles, makeStyles, Theme } from '@agentnet/components';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { ArticleBody } from './components/TopicBody';
import { ContentfulErrorContext, ContentfulErrorTypes } from './hooks/ContentfulErrorContext';
import { useArticleQuery } from './queries/useArticleQuery';
import { Article } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderContainer: {
      height: '40vw',
      width: '65vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    subTopPageBody: {
      width: '100%',
      background: '#fff',
    },
  }),
);
export function ArticlePage() {
  const classes = useStyles();
  const match = useRouteMatch<{ topic: string; subtopic: string; article: string }>();
  const [article, setArticle] = useState<Article | null>();

  const { setContentfulErrorType } = React.useContext(ContentfulErrorContext);

  useEffect(() => {
    const fetchData = async () => {
      setArticle(null);
      const { data, error } = await useArticleQuery(match.params.article);

      if (error) {
        setContentfulErrorType(ContentfulErrorTypes.Error);
      }

      if (!data) {
        setContentfulErrorType(ContentfulErrorTypes.NoContent);
      }

      setArticle(data);
    };
    fetchData();
  }, [match.params.article]);

  return (
    <div className={classes.subTopPageBody}>
      {!article && <LoadingSpinner className={classes.loaderContainer} variant="circle" status={'pending'} />}
      {article && <ArticleBody article={article} isPdf={false} />}
    </div>
  );
}
