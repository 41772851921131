/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStyles, makeStyles, Theme } from '@agentnet/components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Typography, TypographyProps } from '@material-ui/core';
import { HELP_ROUTE } from 'features/help/constants';
import { Article } from 'features/help/types';
import { ContentfulAsset } from 'features/help/types/contentfulAsset';
import { ContentfulJsonDocument, IRichTextDocumentLinks } from 'features/help/types/contentfulJsonDocument';
import { Link } from 'react-router-dom';

export type RenderContentProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  document: ContentfulJsonDocument;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {},
    bold: { fontWeight: 'bold' },
    italic: { fontStyle: 'italic' },
    underline: { textDecoration: 'underline' },
    subscript: { verticalAlign: 'subscript' },
    superscript: { verticalAlign: 'superscript' },
    code: { fontFamily: 'monospace' },
    strikethrough: { textDecoration: 'line-through' },
    paragraph: {
      display: 'block',
      lineHeight: '1.5',
    },
    text: {},
    image: {
      width: '100%',
      maxWidth: 'fit-content',
      height: 'auto',
      margin: theme.spacing(2, 0),
    },
  }),
);

function getOptions(links: IRichTextDocumentLinks, classes: ReturnType<typeof useStyles>) {
  const assetMap = new Map<string, ContentfulAsset>();
  const articleMap = new Map<string, Article>();

  // Loop through all the hyperlink assets and add them to the assetMap
  if (links?.assets?.hyperlink) {
    for (const asset of links.assets.hyperlink) {
      if (asset?.sys?.id) {
        assetMap.set(asset.sys.id, asset);
      }
    }
  }

  // Loop through all the embedded assets and add them to the assetMap
  if (links?.assets?.block) {
    for (const asset of links.assets.block) {
      if (asset?.sys?.id) {
        assetMap.set(asset.sys.id, asset);
      }
    }
  }

  //Loop through all the hyperlink entries and add them to the articleMap
  if (links?.entries?.hyperlink) {
    for (const article of links.entries.hyperlink) {
      if (article?.sys?.id) {
        articleMap.set(article.sys.id, article);
      }
    }
  }

  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_: any, children: any) => {
        return (
          <Typography variant="body1" component="div" className={`${classes.paragraph} paragraph`}>
            {children}
          </Typography>
        );
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        return (
          !!node.data?.uri && (
            <a href={node.data?.uri} color="primary" className={classes.link} target="_blank" rel="noreferrer">
              {children}
            </a>
          )
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
        if (node.data?.target?.sys?.id) {
          const asset = assetMap.get(node.data.target.sys.id);
          return (
            <a href={asset?.url} target="_blank" rel="noreferrer">
              {children}
            </a>
          );
        }
      },
      [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
        const article = articleMap.get(node?.data?.target?.sys?.id);

        if (article) {
          return (
            <Link to={`${HELP_ROUTE}/${article.subTopicCollection.items[0].slug}/${article.slug}`}>{children}</Link>
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const asset = assetMap.get(node.data.target.sys.id);
        if (asset) {
          if (asset.contentType.includes('image')) {
            return (
              <>
                <img className={`${classes.image} asset-image`} src={asset.url} alt={asset.title} />
                <span className="asset-description">{asset.description}</span>
              </>
            );
          } else {
            return (
              <a href={asset.url} target="_blank" rel="noreferrer">
                {asset.fileName}
              </a>
            );
          }
        }
      },
    },
  };
}

export function RenderContent({ document, color }: RenderContentProps & Pick<TypographyProps, 'color'>) {
  if (!document?.json) {
    return null;
  }

  const classes = useStyles();
  const options = getOptions(document.links, classes);

  return (
    <Typography
      variant="body1"
      component="div"
      color={color ?? 'textPrimary'}
      className={`${classes.text} kbArticleBody`}
    >
      {documentToReactComponents(document.json, options)}
    </Typography>
  );
}
